.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-close {
  position: absolute;
    top: 0;
    right: 10px;
    background: transparent;
    color: red;
    font-size: larger;
    font-weight: bolder;
}

.modal-main {
  position:fixed;
  background: white;
  min-width: 25%;
  height: auto;
  top:40%;
  left:50%;
  transform: translate(-50%,-50%);
  min-height: 25%;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}